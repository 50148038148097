import React, { useState } from 'react';
import { ActionModal } from '../modal/Modals';
import { SpacerH, SpacerV } from '../../composing/Spacing';
import { PrimaryButton, SecondaryButton } from '../../composing/CSButton';
import { CSText } from '../../composing/CSText';
import { flexJustified } from '../../theme/AtomicClasses';
import { MemoFeedItem } from '@commonstock/common/src/api/feed';
import AnimatedEllipsis from 'src/components/AnimatedEllipsis';
import { Colors } from 'src/theme/constants';
import { css } from '@linaria/core';
type Props = {
  currentDraft: MemoFeedItem | null;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};
export function SaveDraft({
  onCancel,
  onConfirm,
  currentDraft
}: Props) {
  const [pending, setPending] = useState(false);

  const handleConfirm = async () => {
    setPending(true);
    await onConfirm();
    setPending(false);
  };

  return <ActionModal title="Save Draft?">
      <CSText centered>
        {currentDraft ? 'Do you want to update your draft?' : 'Do you want to save a draft of your memo?'}
      </CSText>
      <SpacerV />

      <div className={flexJustified}>
        <SecondaryButton className={redButton} block large onClick={onCancel}>
          Discard
        </SecondaryButton>
        <SpacerH rem={1} />
        <PrimaryButton block large onClick={handleConfirm}>
          {pending ? <AnimatedEllipsis>{currentDraft ? 'Updating' : 'Saving'}</AnimatedEllipsis> : currentDraft ? 'Update' : 'Save'}
        </PrimaryButton>
      </div>
    </ActionModal>;
}
type ConfirmDraftDeletionProps = {
  onCancel: () => void;
  onConfirm: () => Promise<void>;
};
export function ConfirmDraftDeletion({
  onCancel,
  onConfirm
}: ConfirmDraftDeletionProps) {
  const [pending, setPending] = useState(false);

  const handleConfirm = async () => {
    setPending(true);
    await onConfirm();
    setPending(false);
  };

  return <ActionModal title="Delete editing draft?">
      <CSText centered>Do you want to delete your current editing draft?</CSText>
      <SpacerV />
      <div className={flexJustified}>
        <SecondaryButton block large onClick={onCancel}>
          Cancel
        </SecondaryButton>
        <SpacerH rem={1} />
        <PrimaryButton className={redButton} block large onClick={handleConfirm}>
          {pending ? <AnimatedEllipsis>Discarding</AnimatedEllipsis> : 'Discard'}
        </PrimaryButton>
      </div>
    </ActionModal>;
} // @TODO: discuss with design we need a new button color here

const redButton = "r15tnb0n";

require("../../../.linaria-cache/packages/oxcart/src/scopes/memo-drafts/DraftActions.linaria.module.css");